
$colours: (
  black:         rgb(0, 0, 0),
  
  bright-green0: #029802,
  bright-green1: #16b316,
  
  green0:         rgb(6, 144, 74),
  
  turquoise0:    rgb(0, 197, 153),
  turquoise1:    rgb(43, 228, 179),
  
  pale-green0:   rgb(191, 245, 196),
  pale-green1:   rgb(236, 251, 232),

  pale-grey0:    rgb(203, 203, 203),
  pale-grey1:    rgb(236, 236, 236),
  pale-grey2:    rgb(248, 248, 248), 

  grey0:         #8f8f8f,
  grey1:         #dcdcdc,
);

@use '@carbon/react/scss/config' with (
  $use-flexbox-grid: true,
);

@use '@carbon/react/scss/themes';

@use '@carbon/react/scss/theme' with (
  $fallback: themes.$g10,
  $theme: (
    background-brand: map-get($colours, bright-green0),
    border-interactive: map-get($colours, turquoise0),
    
    border-inverse: map-get($colours, green0),
    layer-selected-inverse: map-get($colours, green0),

    focus: map-get($colours, turquoise0),
    interactive: map-get($colours, bright-green0),

    support-success: map-get($colours, bright-green0),
    support-success-inverse: map-get($colours, bright-green1),

    ai-inner-shadow: map-get($colours, pale-green0),
    ai-drop-shadow: map-get($colours, pale-green1),
    ai-border-start: map-get($colours, green0),
    ai-border-end: map-get($colours, green0),
  )
);

@use '@carbon/react/scss/components/button/tokens' with (
  $button-primary: map-get($colours, bright-green0),
  $button-primary-hover: map-get($colours, bright-green1),

  $button-secondary: #f4f4f4,
  $button-secondary-hover: #e8e8e8,
  // $button-secondary-text: map-get($colours, black),
);


@use '@carbon/react';

body, #root {
  width: 100%;
  height: calc(100dvh - 3rem); // 3rem is the height of the header
}


main {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 672px) {
  main.cds--content {
    padding: 2rem 0;
  }
  
}
main > div {
  width: 100%;
}

h3 {
  border-bottom: 1px solid map-get($colours, grey1);
}